<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>      
      <v-col sm="12" style="position: relative;">      
        <div class="buttons-area">
          <v-btn
            color="grey darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="viewRoute()"
            >Route</v-btn
          >
          <v-btn
            color="grey darken-3"
            class="white--text equalBtn"
            x-large
            depressed
            @click="takeUploadPhotos()"
            >Photos</v-btn
          >
          <v-btn
            v-if="currentOrder.signature_required"
            color="orange darken-1"
            class="white--text equalBtn"
            x-large
            depressed
            @click="getSignature()"
            >Sign</v-btn
          >
          <v-btn
            v-else
            color="orange darken-1"
            class="equalBtn"
            x-large
            depressed
            disabled
            >Sign</v-btn
          >
        </div>  
        <div>        
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >WORK ASSIGNMENT</v-card-title
            >
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Appointment</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{ currentOrder.details.work_assignment.appt_date }} / {{ currentOrder.details.work_assignment.appt_time }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Waybill #</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">{{ currentOrder.waybill_id }}</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Driver name</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{ currentOrder.details.work_assignment.driver }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Truck #</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{ currentOrder.details.work_assignment.truck }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6" cols="6">
                <div class="px-2 pt-3" v-if="driver_allow_updates == 'yes'">
                  <v-form
                    ref="updatechassis"
                    @submit="updateChassis"
                    onsubmit="return false;"
                  >
                    <v-text-field
                      label="Chassis"
                      v-model="current_chassis"
                      :rules="[rules.required, rules.min3]"
                      :value="this.current_chassis"
                      dense
                      color="orange"
                    ></v-text-field>
                  </v-form>
                </div>
                <div class="px-2" v-else>Chassis</div>
              </v-col>
              <v-col sm="6" cols="6">
                <div class="px-2" v-if="driver_allow_updates == 'yes'">
                  <v-btn
                    color="orange darken-1"
                    class="white--text updateBtn"
                    small
                    depressed
                    @click.prevent="updateChassis"
                    >Update</v-btn
                  >
                </div>
                <div class="px-2" v-else>{{ current_chassis }}</div>              
              </v-col>
            </v-row>
            <div class="px-2" v-if="driver_add_chassis == 'yes'">
              <v-row no-gutters class="py-2">
                <v-col sm="12" cols="12" class="d-flex justify-center align-center">
                  <v-dialog
                    transition="dialog-top-transition"
                    v-model="showChassisDialog"
                    persistent
                    max-width="100%"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="orange darken-1"
                        text
                        small
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        >Add New Chassis</v-btn
                      >                   
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Add New Chassis</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-form
                                ref="addchassis"
                                @submit="addChassis"
                                onsubmit="return false;"
                              >
                                <v-text-field
                                  label="Chassis name"
                                  v-model="new_chassis"
                                  :rules="[rules.required, rules.min3]"
                                  :value="this.new_chassis"
                                  :maxlength="15"
                                  dense
                                  color="orange"
                                ></v-text-field>
                                <v-spacer><br/></v-spacer>
                                <v-select
                                  label="Chassis Type"
                                  v-model="selected_type"                             
                                  :items="chassis_types"
                                  :rules="[rules.required]"
                                  dense
                                  color="orange"
                                ></v-select>
                              </v-form>                            
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="gray darken-1"                        
                          text
                          small
                          depressed                          
                          @click="showChassisDialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="orange darken-1"
                          class="white--text"
                          small
                          depressed
                          @click.prevent="addChassis"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>                      
                    </v-card>    
                  </v-dialog>
                </v-col>
              </v-row>
            </div>                     
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>          
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 orange lighten-5"
              style="border-bottom: 1px #ff9800 solid !important;"
              >IMPORTANT NOTES</v-card-title
            >            
            <v-row no-gutters class="py-2">
              <v-col sm="12">
                <div class="px-2">
                  <div v-if="currentOrder.details.work_assignment.notes && currentOrder.details.work_assignment.notes != ''">
                    {{  }}
                    <div
                      class="px-2"
                      v-for="note in currentOrder.details.work_assignment.notes"
                      :key="note.note_id"
                    >
                      <span v-if="note.is_important == 'yes'" class="red--text">
                        {{
                          note.note_text
                        }}                        
                      </span>
                      <span v-else>
                        {{
                          note.note_text
                        }}
                      </span>

                    </div>                    
                  </div>
                  <div v-else>None</div>
                </div>
              </v-col>
            </v-row>            
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >ROUTE INFORMATION</v-card-title
            >
            <v-row no-gutters>
              <v-col sm="12" cols="12">
                <div
                  class="px-2 py-4 title grey--text text--darken-1 text-uppercase text-center"
                >
                  {{ currentOrder.details.route_information.dispatched_status }}
                    <span
                      v-if="
                        currentOrder.details.route_information.dispatched_status == 'Live'
                        && currentOrder.details.route_information.type == 'empty'
                      "> LOAD</span>
                    <span
                      v-if="
                        currentOrder.details.route_information.dispatched_status == 'Live'
                        && currentOrder.details.route_information.type == 'full'
                      "> UNLOAD</span>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-2 py-2">
              <v-col sm="6" cols="3">
                <div
                  class="px-2 font-weight-bold"
                  style="transform: scale(0.80);"
                >
                  From
                </div>
              </v-col>
              <v-col sm="6" cols="9">
                <div
                  class="px-2 orange--text text--darken-1 font-weight-medium"
                >
                  {{ currentOrder.details.route_information.move_from.name }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="12">
                <div class="px-2">
                  {{ currentOrder.details.route_information.move_from.address }}
                  <br />
                  {{ currentOrder.details.route_information.move_from.city }}
                  {{
                    currentOrder.details.route_information.move_from.countrydiv_iso
                  }}
                  {{ currentOrder.details.route_information.move_from.zip }}
                  {{
                    currentOrder.details.route_information.move_from.country_name
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Opening hours</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{
                    currentOrder.details.route_information.move_from.opening_hours
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">
                  <div>Contact</div>
                </div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  <div
                    v-if="
                      currentOrder.details.route_information.move_from.contact.name != '-'
                    "
                  >
                    {{
                      currentOrder.details.route_information.move_from.contact.name
                    }}
                    <br />
                    <a
                      :href="
                        'tel:' +
                          currentOrder.details.route_information.move_from.contact.phone
                      "
                      >{{
                        currentOrder.details.route_information.move_from.contact.phone
                      }}</a
                    >
                    <span
                      v-if="
                        currentOrder.details.route_information.move_from.contact.ext != '-'
                      "
                      >#{{
                        currentOrder.details.route_information.move_from.contact.ext
                      }}</span
                    >
                  </div>
                  <div v-else>N/A</div>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-2 py-2">
              <v-col sm="6" cols="3">
                <div
                  class="px-2 font-weight-bold"
                  style="transform: scale(0.80);"
                >
                  To
                </div>
              </v-col>
              <v-col sm="6" cols="9">
                <div
                  class="px-2 orange--text text--darken-1 font-weight-medium"
                >
                  {{ currentOrder.details.route_information.move_to.name }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="12">
                <div class="px-2">
                  {{ currentOrder.details.route_information.move_to.address }}
                  <br />
                  {{ currentOrder.details.route_information.move_to.city }}
                  {{
                    currentOrder.details.route_information.move_to.countrydiv_iso
                  }}
                  {{ currentOrder.details.route_information.move_to.zip }}
                  {{
                    currentOrder.details.route_information.move_to.country_name
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Opening hours</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{
                    currentOrder.details.route_information.move_to.opening_hours
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">
                  <div>Contact</div>
                </div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  <div
                    v-if="
                      currentOrder.details.route_information.move_to.contact.name != '-'
                    "
                  >
                    {{
                      currentOrder.details.route_information.move_to.contact.name
                    }}
                    <br />
                    <a
                      :href="
                        'tel:' +
                          currentOrder.details.route_information.move_to.contact.phone
                      "
                      >{{
                        currentOrder.details.route_information.move_to.contact.phone
                      }}</a
                    >
                    <span
                      v-if="
                        currentOrder.details.route_information.move_to.contact.ext != '-'
                      "
                      >#{{
                        currentOrder.details.route_information.move_to.contact.ext
                      }}</span
                    >
                  </div>
                  <div v-else>N/A</div>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="12" cols="12">
                <div
                  class="px-2 py-2"
                  v-if="currentOrder.details.route_information.notes != null"
                >
                  {{ "Note: " + currentOrder.details.route_information.notes }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-5">
              <v-col sm="12" cols="12">
                <div class="text-center">
                  <v-btn
                    v-if="currentOrder.signature_required"
                    color="orange darken-1"
                    class="white--text"
                    depressed
                    :disabled="blockCheckIn"
                    @click.prevent="
                      setTimeIn()
                    "
                    >Check in</v-btn
                  >
                </div>
              </v-col>
            </v-row> 
            <!-- Show the return only for "show_return" setting and for "delivery" moves -->
            <div
              v-if="currentOrder.details.work_assignment.return_location != ''
                && currentOrder.details.work_assignment.return != ''
                && currentOrder.details.work_assignment.return_opening_hours != ''
                && currentOrder.details.work_assignment.return_contact != ''
                && driver_show_return == 'yes'"
            >
              <v-row no-gutters class="grey lighten-2 py-2">
                <v-col sm="6" cols="3">
                  <div
                    class="px-2 font-weight-bold"
                    style="transform: scale(0.80);"
                  >
                    Return
                  </div>
                </v-col>
                <v-col sm="6" cols="9">
                  <div
                    class="px-2 orange--text text--darken-1 font-weight-medium"
                  >
                    {{ currentOrder.details.work_assignment.return_location }}
                  </div>
                </v-col>
              </v-row> 
              <v-row no-gutters class="grey lighten-4 py-2">
                <v-col sm="12">
                  <div class="px-2">
                    {{ currentOrder.details.work_assignment.return[0].waypoint_address }}
                    <br />
                    {{ currentOrder.details.work_assignment.return[0].waypoint_city }}
                    {{
                      currentOrder.details.work_assignment.return[0].waypoint_countrydiv_iso
                    }}
                    {{ currentOrder.details.work_assignment.return[0].waypoint_zip }}
                    {{
                      currentOrder.details.work_assignment.return[0].country_name
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="grey lighten-5 py-2">
                <v-col sm="6">
                  <div class="px-2">Opening hours</div>
                </v-col>
                <v-col sm="6">
                  <div class="px-2">
                    {{
                      currentOrder.details.work_assignment.return_opening_hours
                    }}
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="grey lighten-4 py-2">
                <v-col sm="6">
                  <div class="px-2">
                    <div>Contact</div>
                  </div>
                </v-col>
                <v-col sm="6">
                  <div class="px-2">
                    <div
                      v-if="
                        currentOrder.details.work_assignment.return_contact.name != '-'
                      "
                    >
                      {{
                        currentOrder.details.work_assignment.return_contact.name
                      }}
                      <br />
                      <a
                        :href="
                          'tel:' +
                            currentOrder.details.work_assignment.return_contact.phone
                        "
                        >{{
                          currentOrder.details.work_assignment.return_contact.phone
                        }}</a
                      >
                      <span
                        v-if="
                          currentOrder.details.work_assignment.return_contact.ext != '-'
                        "
                        >#{{
                          currentOrder.details.work_assignment.return_contact.ext
                        }}</span
                      >
                    </div>
                    <div v-else>N/A</div>
                  </div>
                </v-col>
              </v-row>              
            </div>                      
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >LOAD INFORMATION</v-card-title
            >
            <v-row no-gutters class="grey lighten-2 py-2">
              <v-col sm="12">
                <div class="px-2">Description</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="12">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.load_information.description != null &&
                      currentOrder.details.load_information.description != ''
                  "
                >
                  {{ currentOrder.details.load_information.description }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Number of pieces</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.load_information.number_of_pieces !=
                      null &&
                      currentOrder.details.load_information.number_of_pieces !=
                        ''
                  "
                >
                  {{ currentOrder.details.load_information.number_of_pieces }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Temperature</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.load_information.temperature != null &&
                      currentOrder.details.load_information.temperature != ''
                  "
                >
                  {{ currentOrder.details.load_information.temperature }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <!-- for import allow updates-->
            <div
              v-if="currentOrder.details.route_information.type != 'empty'"
            >
              <v-row no-gutters class="grey lighten-5 py-2">
                <v-col sm="6">
                  <div class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-form
                      ref="updateweight"
                      @submit="updateWeight"
                      onsubmit="return false;"
                    >                  
                      <v-text-field
                        label="Weight"
                        v-model="weight"
                        type="number"
                        :rules="[rules.required, rules.numbers]"
                        :value="this.weight"
                        :maxlength="20"
                        :suffix="this.weight_type"
                        dense
                        color="orange"                      
                      ></v-text-field>                      
                    </v-form>
                  </div>
                  <div v-else>
                    <div class="px-2">Weight</div>
                  </div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-btn
                      color="orange darken-1"
                      class="white--text updateBtn"
                      small
                      depressed
                      @click.prevent="
                        updateWeight()
                      "
                      >Update</v-btn
                    >
                  </div>
                  <div v-else>
                    <div
                      class="px-2"
                      v-if="weight != null && weight != ''"
                    >
                      {{ weight }}
                      <span
                        class="px-2"
                        v-if="
                          this.weight_type != null &&
                            this.weight_type != ''
                        "
                      >
                        {{ this.weight_type }}
                      </span>
                    </div>
                    <div class="px-2" v-else>N/A</div>
                  </div>
                </v-col>
              </v-row>            
            </div>
            <div v-else>
              <v-row no-gutters class="grey lighten-5 py-2">
                <v-col sm="12">
                  <div class="px-2">Weight</div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="weight != null && weight != ''"
                  >
                    {{ weight }}
                      <span
                        class="px-2"
                        v-if="
                          this.weight_type != null &&
                            this.weight_type != ''
                        "
                      >
                        {{ this.weight_type }}
                      </span>                    
                  </div>
                  <div class="px-2" v-else>N/A</div>
                </v-col>
              </v-row>            
            </div>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Tare</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.load_information.tare != null &&
                      currentOrder.details.load_information.tare != ''
                  "
                >
                  {{ currentOrder.details.load_information.tare }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-2 py-2">
              <v-col sm="12">
                <div class="px-2">Containers</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="12">
                <!-- for export allow updates-->
                <div
                  v-if="currentOrder.details.route_information.type != 'empty'"
                >
                  <div
                    class="px-2"
                    v-for="container in containers"
                    :key="container.count"
                  >
                    {{
                      container.type +
                        " " +
                        container.size +
                        " " +
                        container.number
                    }}
                  </div>
                </div>
                <div v-else>
                  <div v-for="container in containers" :key="container.id">
                    <form
                      ref="container.id"
                      @submit.prevent="updateContainerNumbers(container.id)"
                    >
                      <v-row no-gutters>
                        <v-col sm="12">
                          <div class="px-2">
                            {{ container.type + " " + container.size }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="6" cols="7">
                          <div
                            class="px-2"
                            v-if="driver_allow_updates == 'yes'"
                          >
                            <v-text-field
                              :id="container.id"
                              :v-model="container_number"
                              :value="
                                container.number != '___________'
                                  ? container.number
                                  : ''
                              "
                              :maxlength="11"
                              class="pt-3"
                              label="Container Number"
                              dense
                              color="orange"
                              onkeyup="this.value = this.value.toUpperCase();"
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col sm="6" cols="5">
                          <div
                            class="px-2"
                            v-if="driver_allow_updates == 'yes'"
                          >
                            <v-btn
                              color="orange darken-1"
                              class="white--text updateBtn"
                              small
                              depressed
                              @click.prevent="
                                updateContainerNumbers(container.id)
                              "
                              >Update</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </form>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >REFERENCE NUMBERS</v-card-title
            >
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">P.O. Number</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.po_number != null &&
                      currentOrder.details.reference_numbers.po_number != ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.po_number }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Pickup Number</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.pickup_number !=
                      null &&
                      currentOrder.details.reference_numbers.pickup_number != ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.pickup_number }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Booking Number</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.booking_number !=
                      null &&
                      currentOrder.details.reference_numbers.booking_number !=
                        ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.booking_number }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="grey lighten-5 py-2"
              v-if="
                currentOrder.details.reference_numbers.pickup_rv_no != null &&
                  currentOrder.details.reference_numbers.pickup_rv_no != ''
              "
            >
              <v-col sm="6">
                <div class="px-2">Pickup RV #</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{ currentOrder.details.reference_numbers.pickup_rv_no }}
                </div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="grey lighten-4 py-2"
              v-if="
                currentOrder.details.reference_numbers.return_rv_no != null &&
                  currentOrder.details.reference_numbers.return_rv_no != ''
              "
            >
              <v-col sm="6">
                <div class="px-2">Return RV #</div>
              </v-col>
              <v-col sm="6">
                <div class="px-2">
                  {{ currentOrder.details.reference_numbers.return_rv_no }}
                </div>
              </v-col>
            </v-row>
            <!-- for import allow updates-->
            <div
              v-if="currentOrder.details.route_information.type != 'empty'"
            >
              <v-row no-gutters class="grey lighten-5 py-2">
                <v-col sm="6">
                  <div class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-form
                      ref="updatesealno"
                      @submit="updateSealNo"
                      onsubmit="return false;"
                    >                  
                      <v-text-field
                        label="Seal Number"
                        v-model="seal_no"
                        :rules="[rules.required, rules.min3]"
                        :value="this.seal_no"
                        :maxlength="20"
                        dense
                        color="orange"                      
                      ></v-text-field>
                    </v-form>
                  </div>
                  <div v-else>
                    <div class="px-2">Seal Number</div>
                  </div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-btn
                      color="orange darken-1"
                      class="white--text updateBtn"
                      small
                      depressed
                      @click.prevent="
                        updateSealNo()
                      "
                      >Update</v-btn
                    >
                  </div>
                  <div v-else>
                    <div
                      class="px-2"
                      v-if="seal_no != null && seal_no != ''"
                    >
                      {{ seal_no }}
                    </div>
                    <div class="px-2" v-else>N/A</div>
                  </div>
                </v-col>
              </v-row>  
            </div>
            <div v-else>
              <v-row no-gutters class="grey lighten-5 py-2">
                <v-col sm="12">
                  <div class="px-2">Seal Number</div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="seal_no != null && seal_no != ''"
                  >
                    {{ seal_no }}
                  </div>
                  <div class="px-2" v-else>N/A</div>
                </v-col>
              </v-row>            
            </div>
            <!-- for import allow updates-->            
            <div
              v-if="currentOrder.details.route_information.type != 'empty'"
            >
              <v-row no-gutters class="grey lighten-4 py-2">
                <v-col sm="6">
                  <div class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-form
                      ref="updatebolno"
                      @submit="updateBOLNo"
                      onsubmit="return false;"
                    >                  
                      <v-text-field
                        label="Bill Of Lading"
                        v-model="bol_no"
                        :rules="[rules.required, rules.min3]"
                        :value="this.bol_no"
                        :maxlength="20"
                        dense
                        color="orange"                      
                      ></v-text-field>
                    </v-form>
                  </div>
                  <div v-else>
                    <div class="px-2">Bill Of Lading</div>
                  </div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="driver_extra_updates == 'yes'"
                  >
                    <v-btn
                      color="orange darken-1"
                      class="white--text updateBtn"
                      small
                      depressed
                      @click.prevent="
                        updateBOLNo()
                      "
                      >Update</v-btn
                    >
                  </div>
                  <div v-else>
                    <div
                      class="px-2"
                      v-if="bol_no != null && bol_no != ''"
                    >
                      {{ bol_no }}
                    </div>
                    <div class="px-2" v-else>N/A</div>
                  </div>
                </v-col>
              </v-row>            
            </div>            
            <div v-else>
              <v-row no-gutters class="grey lighten-4 py-2">
                <v-col sm="12">
                  <div class="px-2">Bill Of Lading</div>
                </v-col>
                <v-col sm="6">
                  <div
                    class="px-2"
                    v-if="bol_no != null && bol_no != ''"
                  >
                    {{ bol_no }}
                  </div>
                  <div class="px-2" v-else>N/A</div>
                </v-col>
              </v-row>              
            </div>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Client Ref. #</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.client_ref_number !=
                      null &&
                      currentOrder.details.reference_numbers
                        .client_ref_number != ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.client_ref_number }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Pickup Customer</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.pickup_customer !=
                      null &&
                      currentOrder.details.reference_numbers.pickup_customer !=
                        ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.pickup_customer }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Vessel</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers.vessel != null &&
                      currentOrder.details.reference_numbers.vessel != ''
                  "
                >
                  {{ currentOrder.details.reference_numbers.vessel }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="6">
                <div class="px-2">Steamship Line</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers
                      .steam_ship_line_name != null &&
                      currentOrder.details.reference_numbers
                        .steam_ship_line_name != ''
                  "
                >
                  {{
                    currentOrder.details.reference_numbers.steam_ship_line_name
                  }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-5 py-2">
              <v-col sm="6">
                <div class="px-2">Port of Destination</div>
              </v-col>
              <v-col sm="6">
                <div
                  class="px-2"
                  v-if="
                    currentOrder.details.reference_numbers
                      .port_of_destination_name != null &&
                      currentOrder.details.reference_numbers
                        .port_of_destination_name != ''
                  "
                >
                  {{
                    currentOrder.details.reference_numbers
                      .port_of_destination_name
                  }}
                </div>
                <div class="px-2" v-else>N/A</div>
              </v-col>
            </v-row>
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>
          <v-card class="mx-auto my-4" max-width="90%">
            <v-card-title
              class="title justify-center grey--text text--darken-1 grey lighten-2"
              style="border-bottom: 1px #ff9800 solid !important;"
              >COMPANY INFORMATION</v-card-title
            >
            <v-row no-gutters class="grey lighten-2 py-2">
              <v-col sm="6" cols="12">
                <div class="px-2">
                  {{ currentOrder.details.company_information.name }}
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters class="grey lighten-4 py-2">
              <v-col sm="12">
                <div class="px-2">
                  {{ currentOrder.details.company_information.address }}
                  <br />
                  {{ currentOrder.details.company_information.city + ", " }}
                  {{ currentOrder.details.company_information.state }}
                  {{ currentOrder.details.company_information.zip }}
                  {{ currentOrder.details.company_information.country }}
                </div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="grey lighten-5 py-2"
              v-if="
                currentOrder.details.company_information.nir_no != null &&
                  currentOrder.details.company_information.nir_no != ''
              "
            >
              <v-col sm="6" cols="4">
                <div class="px-2">NIR #</div>
              </v-col>
              <v-col sm="6" cols="8">
                <div class="px-2">
                  {{ currentOrder.details.company_information.nir_no }}
                </div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="grey lighten-4 py-2"
              v-if="
                currentOrder.details.company_information.cvor_no != null &&
                  currentOrder.details.company_information.cvor_no != ''
              "
            >
              <v-col sm="6" cols="4">
                <div class="px-2">CVOR #</div>
              </v-col>
              <v-col sm="6" cols="8">
                <div class="px-2">
                  {{ currentOrder.details.company_information.cvor_no }}
                </div>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="grey lighten-5 py-2"
              v-if="
                currentOrder.details.company_information.phone != null &&
                  currentOrder.details.company_information.phone != ''
              "
            >
              <v-col sm="6" cols="4">
                <div class="px-2">Phone</div>
              </v-col>
              <v-col sm="6" cols="8">
                <div class="px-2">
                  <a
                    :href="
                      'tel:' + currentOrder.details.company_information.phone
                    "
                    >{{ currentOrder.details.company_information.phone }}</a
                  >
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-row no-gutters class="my-5">
            <v-col sm="12" cols="12"></v-col>
          </v-row>
        </div>
        <v-layout row v-if="message.text">
          <v-flex xs12 sm6 px-1 ma-0>
            <app-alert
              :textAlert="message.text"
              :typeAlert="message.type"
              @dismissed="onDismissed()"
            ></app-alert>
          </v-flex>
        </v-layout>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderDetails",
  mounted() {
    this.$store.dispatch("checkDriverStatus");
    setTimeout(() => {
      if (this.driver_status == "active") {
        //set default vars only first time when they are null
        if (this.defaultChassis == null) {
          this.$store.dispatch(
            "setDefaultChassis",
            this.currentOrder.details.work_assignment.chassis
          );          
        }
        if (this.currentWeight == null) {
          this.$store.dispatch(
            "setWeight",
            this.currentOrder.details.load_information.weight
          );          
        }        
        if (this.currentSealNo == null) {
          this.$store.dispatch(
            "setSealNo",
            this.currentOrder.details.reference_numbers.seal_number
          );          
        } 
        if (this.currentBOLNo == null) {
          this.$store.dispatch(
            "setBOLNo",
            this.currentOrder.details.reference_numbers.bill_of_lading
          );           
        }
              
        this.current_chassis = this.defaultChassis;
        this.weight_type = this.currentOrder.details.load_information.weight_type;
        this.weight = this.currentWeight;
        this.seal_no = this.currentSealNo;
        this.bol_no = this.currentBOLNo;
        this.containers = this.currentOrder.details.load_information.containers;
        this.verified_digit = "";
        //console.log("default chassis: " + this.defaultChassis);
        setTimeout(() => {
          this.$store.dispatch("sendReadReceipt", this.$route.params.mid);
        }, 300);
      }
    }, 500);

    let time_in = this.getTimeInByDriver();
    if (time_in == null) {
      this.blockCheckIn = false;
    } else {
      this.blockCheckIn = true;
    }
    //console.log(time_in);
  },
  computed: {
    message() {
      return this.$store.getters.message;
    },
    driver_allow_updates() {
      return this.$store.getters.user.allow_updates;
    },
    driver_extra_updates() {
      return this.$store.getters.user.extra_updates;
    },
    driver_show_return() {
      return this.$store.getters.user.show_return;
    },    
    driver_add_chassis() {
      return this.$store.getters.user.add_chassis;
    },     
    currentOrder() {
      return this.$store.getters.currentOrders.find((move) => {
        return move.movement_id == this.$route.params.mid;
      });
    },
    defaultChassis() {
      return this.$store.getters.defaultChassis;
    },
    currentWeight() {
      return this.$store.getters.currentWeight;
    },     
    currentSealNo() {
      return this.$store.getters.currentSealNo;
    },    
    currentBOLNo() {
      return this.$store.getters.currentBOLNo;
    },    
    driver_status() {
      return this.$store.getters.user.status;
    },
    auth_token() {
      return this.$store.getters.token;
    },
  },
  methods: {
    viewRoute() {
      this.$router.push("./../route-map/" + this.currentOrder.movement_id);
    },
    takeUploadPhotos() {
      //this.$router.push("./../camera/" + this.currentOrder.movement_id);
    },
    getTimeInByDriver() {
      return this.$store.getters.timeInByDriver;
    },
    getSignature() {
      let time_in = this.getTimeInByDriver();
      if (time_in == null) {
        this.$store.dispatch("setMessage", {
          type: "error",
          text: "Please be sure to check in before signing",
        });
      } else {
        this.$router.push("./../signature/" + this.currentOrder.movement_id);
      }
    },
    updateChassis() {
      if (this.$refs.updatechassis.validate()) {
        this.$store.dispatch("updateChassis", {
          move_id: this.$route.params.mid,
          appt_date: this.currentOrder.details.work_assignment.appt_date,
          chassis: this.current_chassis.replace(/\s/g, "").toUpperCase(),
        });
        this.$store.dispatch(
          "setDefaultChassis",
          this.current_chassis.replace(/\s/g, "").toUpperCase()
        );  
        this.current_chassis = this.defaultChassis;      
      }
    },
    addChassis() {
      if (this.$refs.addchassis.validate()) {
        this.$store.dispatch("addChassis", {
          move_id: this.$route.params.mid,
          appt_date: this.currentOrder.details.work_assignment.appt_date,
          new_chassis: this.new_chassis.replace(/\s/g, "").toUpperCase(),
          chassis_type: this.selected_type
        });
        this.$store.dispatch(
          "setDefaultChassis",
          this.new_chassis.replace(/\s/g, "").toUpperCase()
        );
        this.current_chassis = this.defaultChassis;
        this.showChassisDialog = false;       
        this.new_chassis = "";
        this.selected_type = {};               
      }
    },
    updateWeight() {
      if (this.$refs.updateweight.validate()) { 
        this.$store.dispatch("updateWeight", {
          move_id: this.$route.params.mid,
          appt_date: this.currentOrder.details.work_assignment.appt_date,
          weight: this.weight.replace(/\s/g, "").toUpperCase(),
          weight_type: this.weight_type.replace(/\s/g, "").toUpperCase()
        });
        this.$store.dispatch(
          "setWeight",
          this.weight.replace(/\s/g, "").toUpperCase()
        );   
        this.weight = this.currentWeight;       
      }
    },    
    updateSealNo() {
      if (this.$refs.updatesealno.validate()) { 
        this.$store.dispatch("updateSealNo", {
          move_id: this.$route.params.mid,
          appt_date: this.currentOrder.details.work_assignment.appt_date,
          sealno: this.seal_no.replace(/\s/g, "").toUpperCase(),
        });
        this.$store.dispatch(
          "setSealNo",
          this.seal_no.replace(/\s/g, "").toUpperCase()
        );   
        this.seal_no = this.currentSealNo;       
      }
    },
    updateBOLNo() {
      if (this.$refs.updatebolno.validate()) { 
        this.$store.dispatch("updateBOLNo", {
          move_id: this.$route.params.mid,
          appt_date: this.currentOrder.details.work_assignment.appt_date,
          bolno: this.bol_no.replace(/\s/g, "").toUpperCase(),
        });
        this.$store.dispatch(
          "setBOLNo",
          this.bol_no.replace(/\s/g, "").toUpperCase()
        );   
        this.bol_no = this.currentBOLNo;             
      }
    },    
    validateContainerNumber(container_number) {
      var letters = {};
      letters.A = 10;
      letters.B = 12;
      letters.C = 13;
      letters.D = 14;
      letters.E = 15;
      letters.F = 16;
      letters.G = 17;
      letters.H = 18;
      letters.I = 19;
      letters.J = 20;
      letters.K = 21;
      letters.L = 23;
      letters.M = 24;
      letters.N = 25;
      letters.O = 26;
      letters.P = 27;
      letters.Q = 28;
      letters.R = 29;
      letters.S = 30;
      letters.T = 31;
      letters.U = 32;
      letters.V = 34;
      letters.W = 35;
      letters.X = 36;
      letters.Y = 37;
      letters.Z = 38;

      var mx = 1;
      var subtotal = 0;

      if (container_number.length >= 10 && container_number.length <= 11) {
        for (var i = 0, len = 10; i < len; i++) {
          if (i <= 3) {
            //letters
            subtotal += letters[container_number[i]] * mx; //letters;
          } else {
            //numbers
            subtotal += container_number[i] * mx; //numbers
          }
          mx = mx * 2;
        }

        var check_total = Math.floor(subtotal / 11) * 11;

        var check_digit = subtotal - check_total;

        if (check_digit == 10) {
          check_digit = 0;
        }

        if (container_number[container_number.length - 1] != "undefined") {
          var last_digit = container_number[container_number.length - 1];
        }

        if (check_digit == last_digit && container_number.length > 10) {
          //Container number is valid
          return true;
        } else {
          //Last digit is: check_digit
          this.verified_digit = check_digit;
          return false;
        }
      } else {
        //Invalid container number
        return false;
      }
    },
    updateContainerNumbers(container_id) {
      this.$store.dispatch("clearMessage");
      this.container_number = document
        .getElementById(container_id)
        .value.replace(/\s/g, "")
        .toUpperCase();
      if (this.validateContainerNumber(this.container_number)) {
        //save container number
        this.$store.dispatch("updateContainerNumber", {
          waybill_id: this.currentOrder.waybill_id,
          container_id: container_id,
          container_number: this.container_number,
        });
      } else {
        if (this.verified_digit !== "") {
          this.$store.dispatch("setMessage", {
            type: "error",
            text: "Last digit is " + this.verified_digit + ", please check your input",
          });       
        } else {
          this.$store.dispatch("setMessage", {
            type: "error",
            text: "Invalid container number, please check your input",
          });          
        }

      }
      this.verified_digit = "";
    },
    setTimeIn() {
      let checkintime = moment().format('HH:mm');
      this.$store.dispatch(
        "setTimeInByDriver",
        checkintime
      );
      this.blockCheckIn = true;
      this.$store.dispatch("setMessage", {
        type: "info",
        text: "Check in registered at " + checkintime,
      });
      setTimeout(() => {
        this.$store.dispatch("sendCheckInReceipt", this.$route.params.mid);
      }, 2500);
    },
    onDismissed() {
      this.$store.dispatch("clearMessage");
    },
  },
  components: {},
  data: () => ({
    current_order: {},
    current_chassis: "",
    new_chassis: "",
    weight: "",
    weight_type: "",
    seal_no: "",
    bol_no: "",
    chassis_types: ['12 PIN TRI', '20 LEAD TAN', '20 TRI', '20/40 SLD', '20/40 TAN', '20/40 TRI', '20/40/45 TRI', '20/45 TRI', '4 PIN QUAD', '4 PIN TAN', '4 PIN TRI', '4 PIN TRI G', '40 QUAD', '40 TAN', '40 TRI', '40/45 4 PIN QUAD', '40/45 4 PIN TRI', '40/45 TAN', '40/45 TRI', '40/53 TAN', '40/53 TRI', '8 PIN TAN', '8 PIN TRI', 'B-TRAIN'],
    selected_type: "",
    containers: [],
    container_number: "",
    verified_digit: "",
    blockCheckIn: false,
    showChassisDialog: false,
    rules: {
      required: (value) => !!value || "Required",
      min3: (v) => v.length >= 3 || "Min 3 characters",
      select: (v) => v.length > 0 || "Required",
      numbers: (v) => /^[0-9]{3,5}$/.test(v) || "Invalid",
    },
  }),
};
</script>
<style scoped>
.buttons-area {
  position: relative;
  top: 2px;
  margin-bottom: 40px;
  width: 100%;
  height: 50px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.equalBtn {
  border-radius: 0px;
  width: 33.33%;
  height: -webkit-fill-available !important;
  font-size: 19px;
}
.updateBtn {
  position: relative;
  top: 27px;
  margin-left: 25px;
}
</style>
